import React from 'react'

import { Input, Row, Col, Form, Select, Alert, Button, Popconfirm } from 'antd'

/*
  Custom Components Import
*/
import TableKeyValueEditableDND from '../../../../../reusables/components/table-key-value-editable-dnd'
import MemoryStore from '../../../../../utils/memory-store'
import CompileTemplateIcon from '../../../../../reusables/components/compile-template-icon'
import AgiliteTheme from '../../../../../utils/agilite-theme'
import Enums from '../../../../../utils/enums'

class ConnectorsFormTypeWebAPI extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.updateQueryParams = this.updateQueryParams.bind(this)
    this.updateHeaderParams = this.updateHeaderParams.bind(this)

    this.state = {
      authType: this.props.entry.authType ? this.props.entry.authType : '',
      credentialsEnabled: this.entry.custom.isNewDoc
    }
  }

  onFieldChange(key, value) {
    this.entry.custom.isModified = true
    this.props.entry[key] = value
  }

  updateQueryParams(data) {
    this.entry.custom.isModified = true
    this.props.entry.queryParams = data
  }

  updateHeaderParams(data) {
    this.entry.custom.isModified = true
    this.props.entry.headerParams = data
  }

  render() {
    return (
      <>
        <Row justify='space-between'>
          <Col span={10}>
            <Form.Item>
              {'Authentication Type '}
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                value={this.state.authType}
                onChange={value => {
                  this.onFieldChange('authType', value)
                  this.setState({ authType: value })
                }}>
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='basic'>Basic</Select.Option>
                <Select.Option value={Enums.VALUES_STRINGS.MS_GRAPH_BEARER}>MS Graph Bearer</Select.Option>
              </Select>
            </Form.Item>
            {this.state.authType === Enums.VALUES_STRINGS.MS_GRAPH_BEARER ? (
              <div>
                <Form.Item>
                  <span style={{ color: 'red' }}>* </span>
                  {'Tenant '}
                  <Input
                    name='tenant'
                    placeholder='Please provide a Tenant (GUID or Friendly Name)'
                    disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                    autoComplete='new-password'
                    defaultValue={this.props.entry.tenant}
                    onChange={e => {
                      this.onFieldChange('tenant', e.target.value)
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <span style={{ color: 'red' }}>* </span>
                  {'Client ID '}
                  <Input
                    name='client_id'
                    placeholder={this.state.credentialsEnabled ? 'Please provide a Client ID' : 'Credential Provided'}
                    disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader' || !this.state.credentialsEnabled}
                    autoComplete='new-password'
                    type={!this.state.credentialsEnabled ? 'text' : 'password'}
                    defaultValue={this.state.credentialsEnabled ? this.props.entry.clientId : ''}
                    onChange={e => {
                      this.onFieldChange('clientId', e.target.value)
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <span style={{ color: 'red' }}>* </span>
                  {'Client Secret '}
                  <Input
                    name='client_secret'
                    placeholder={this.state.credentialsEnabled ? 'Please provide a Client Secret' : 'Credential Provided'}
                    disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader' || !this.state.credentialsEnabled}
                    autoComplete='new-password'
                    type={!this.state.credentialsEnabled ? 'text' : 'password'}
                    defaultValue={this.state.credentialsEnabled ? this.props.entry.clientSecret : ''}
                    onChange={e => {
                      this.onFieldChange('clientSecret', e.target.value)
                    }}
                  />
                </Form.Item>
              </div>
            ) : null}
            {this.state.authType === 'basic' ? (
              <div>
                <Form.Item>
                  <span style={{ color: 'red' }}>* </span>
                  {'Username '}
                  <CompileTemplateIcon />
                  <Input
                    name='username'
                    placeholder='Please provide a Username'
                    disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                    autoComplete='new-password'
                    defaultValue={this.props.entry.username}
                    onChange={e => {
                      this.onFieldChange('username', e.target.value)
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <span style={{ color: 'red' }}>* </span>
                  {'Password '}
                  <CompileTemplateIcon />
                  <Input
                    name='password'
                    placeholder='Please provide a Password'
                    disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                    autoComplete='new-password'
                    type='password'
                    defaultValue={this.props.entry.password}
                    onChange={e => {
                      this.onFieldChange('password', e.target.value)
                    }}
                  />
                </Form.Item>
              </div>
            ) : null}
            {this.state.authType !== Enums.VALUES_STRINGS.MS_GRAPH_BEARER ? (
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>
                {'Web API Base URL '}
                <CompileTemplateIcon />
                <Input
                  name='url'
                  placeholder="e.g. http://api.acme.com (Don\'t end URL with /)"
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  defaultValue={this.props.entry.url}
                  onChange={e => {
                    this.onFieldChange('url', e.target.value)
                  }}
                />
              </Form.Item>
            ) : null}
          </Col>
          {this.state.authType === Enums.VALUES_STRINGS.MS_GRAPH_BEARER ? (
            <Col span={10}>
              <Alert
                type='info'
                message={<b>Microsoft Graph Bearer Authentication</b>}
                description={
                  <>
                    <p>
                      Please provide the required information. Authentication will be managed automatically.
                      <br />
                      Reference link below to learn more.
                    </p>
                    <a href='https://learn.microsoft.com/en-us/graph/auth-v2-service' target='_blank' rel='noreferrer'>
                      https://learn.microsoft.com/en-us/graph/auth-v2-service
                    </a>
                  </>
                }
              />
            </Col>
          ) : null}
        </Row>
        {this.state.authType !== Enums.VALUES_STRINGS.MS_GRAPH_BEARER ? (
          <Row>
            <Col span={20}>
              <h3>
                Query Parameters <CompileTemplateIcon />
              </h3>
              <TableKeyValueEditableDND
                values={this.props.entry.queryParams ? this.props.entry.queryParams : [{ paramKey: '', paramValue: '' }]}
                columnTitle='Key'
                theme={this.props.theme}
                privileges={MemoryStore.userProfile.teamPrivileges.connectors}
                disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                callback={this.updateQueryParams}
                fields={['paramKey', 'paramValue']}
              />
              <br />
              <h3>
                Header Parameters <CompileTemplateIcon />
              </h3>
              <TableKeyValueEditableDND
                values={this.props.entry.headerParams ? this.props.entry.headerParams : [{ paramKey: '', paramValue: '' }]}
                columnTitle='Key'
                theme={this.props.theme}
                privileges={MemoryStore.userProfile.teamPrivileges.connectors}
                disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                callback={this.updateHeaderParams}
                fields={['paramKey', 'paramValue']}
              />
            </Col>
          </Row>
        ) : (
          <Row hidden={this.state.credentialsEnabled} style={{ marginBottom: 20 }}>
            <Col>
              <Popconfirm
                title='Are you sure you want to reset the credentials?'
                okText='Yes'
                cancelText='No'
                onConfirm={() => {
                  this.setState({
                    credentialsEnabled: true
                  })
                  this.entry.custom.credentialsReset = true
                }}>
                <Button
                  style={{
                    color: AgiliteTheme.dangerColor,
                    cursor: 'pointer'
                  }}>
                  Reset Credentials
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        )}
      </>
    )
  }
}

export default ConnectorsFormTypeWebAPI
