import React from 'react'
import MemoryStore from '../../../utils/memory-store'
import find from 'lodash/find'
import { Row, Form, Tabs, Card, Col, Dropdown, Menu, Popconfirm, Modal, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

/*
  Custom Components Import
*/
import ProcessStepFormGeneral from './process-step-form-general'
import StepOptionContainer from '../../containers/step-options-container'
import VisibleObjectsTableEditableDND from '../../../reusables/components/visible-objects-table-editable-dnd'
import TableKeyValueEditableDND from '../../../reusables/components/table-key-value-editable-dnd'

const TabPane = Tabs.TabPane

class ProcessStepForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.processStepKey = ''

    let step = null
    let tmpTemplate = []
    let showPopulateButton = false

    if (this.props.entry.stepType !== 'First Step') {
      step = find(this.props.data.processSteps, { stepType: 'First Step' })

      if (step) {
        if (step.visibleObjects.length > 0) {
          tmpTemplate = step.visibleObjects
          showPopulateButton = true
        }
      }
    }

    this.state = {
      entry: this.props.entry,
      visibleObjectsTemplate: tmpTemplate,
      showPopulateButton: showPopulateButton,
      populateButtonLabel: 'Populate from First Step',
      modalOpen: false,
      errorText: '',
      btnDisabled: false,
      key: this.props.entry.key
    }

    this.populateFromTemplate = this.populateFromTemplate.bind(this)
    this.updateVisibleObjects = this.updateVisibleObjects.bind(this)
    this.updateKeywords = this.updateKeywords.bind(this)
  }

  populateFromTemplate (tmpArray) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.visibleObjects = JSON.parse(JSON.stringify(tmpArray))
    return true
  }

  updateVisibleObjects (data) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.visibleObjects = data
  }

  updateKeywords (result) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.keywords = result
  }

  getButtonProps () {
    if (this.state.btnDisabled) {
      return {
        disabled: this.state.btnDisabled
      }
    } else {
      return {
        disabled: this.state.btnDisabled,
        style: {
          backgroundColor: '#67AD5B',
          borderColor: '#67AD5B'
        }
      }
    }
  }

  onProcessStepKeyChange (value) {
    this.processStepKey = value

    if (this.processStepKey === this.props.entry.key) {
      this.setState({
        errorText: 'New Process Step Key cannot be the same as existing Process Step Key',
        btnDisabled: true
      })
    } else if (!this.processStepKey.trim()) {
      this.setState({
        errorText: 'Process Step Key cannot be empty',
        btnDisabled: true
      })
    } else {
      this.setState({ errorText: '', btnDisabled: false })
    }
  }

  updateOptiontNextStep (oldstepKey, newStepKey) {
    for (const tmpStep of this.props.data.processSteps) {
      for (const tmpOption of tmpStep.stepOptions) {
        if (tmpOption.nextStep === oldstepKey) {
          tmpOption.nextStep = newStepKey
        }
      }
    }
  }

  render () {
    const menu = (
      <Menu>
        <Menu.Item key='0'>
          <Popconfirm
            title='Are you sure you want to change the Process Step Key?'
            onConfirm={() => {
              this.processStepKey = this.props.entry.key
              this.setState({ modalOpen: true })
              this.onProcessStepKeyChange(this.props.entry.key)
            }}
            okText='Yes'
            cancelText='No'
            placement='left'
          >
            <span style={{ padding: '10 0' }}>Edit Process Step Key</span>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    )

    return (
      <Row>
        <Col span={24}>
          <Form>
            <Card
              extra={!this.props.entry.isNewEntry ? 
                <Dropdown overlay={menu} trigger={['click']}>
                  <FontAwesomeIcon
                    icon={faEllipsisV}
                    style={{
                      padding: 5,
                      fontSize: 17,
                      color: '#B4483E',
                      cursor: 'pointer',
                      margin: -10
                    }}
                  />
                </Dropdown>
                : null}
            >
              <Tabs defaultActiveKey='1' animated={false}>
                <TabPane tab='General' key='1'>
                  <ProcessStepFormGeneral
                    entry={this.props.entry}
                    theme={this.props.theme}
                    templateState={this.props.templateState}
                    tabKey={this.props.tabKey}
                    processSteps={this.props.data.processSteps}
                    isNewDoc={this.props.isNewDoc}
                    stepKey={this.state.key}
                  />
                </TabPane>
                <TabPane tab='Object Rules' key='2'>
                  <VisibleObjectsTableEditableDND
                    entry={this.props.entry}
                    theme={this.props.theme}
                    title='Visible Objects'
                    disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                    callback={this.updateVisibleObjects}
                    populateFromTemplate={this.populateFromTemplate}
                    template={this.state.visibleObjectsTemplate}
                    showPopulateButton={this.state.showPopulateButton}
                    populateLabel={this.state.populateButtonLabel}
                    tabKey={this.props.tabKey}
                  />
                </TabPane>
                <TabPane tab='Step Options' key='3'>
                  <StepOptionContainer
                    entry={this.props.entry.stepOptions}
                    data={this.props.entry}
                    processSteps={this.props.data.processSteps}
                    theme={this.props.theme}
                    tabKey={this.props.tabKey}
                    isNewDoc={this.props.isNewDoc}
                  />
                </TabPane>
                <TabPane tab='Keywords' key='4'>
                  <TableKeyValueEditableDND
                    columnTitle='Key'
                    values={this.props.entry.keywords ? this.props.entry.keywords : []}
                    theme={this.props.theme}
                    privileges={MemoryStore.userProfile.teamPrivileges.bpm}
                    disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                    callback={this.updateKeywords}
                  />
                </TabPane>
              </Tabs>
            </Card>
          </Form>
        </Col>
        <Modal
          title='Change Process Step Key'
          onCancel={() => this.setState({ modalOpen: false })}
          onOk={() => {
            const oldStepKey = this.props.entry.key
            this.entry.custom.isModifiedSubform = true
            this.props.entry.key = this.processStepKey
            this.setState({ key: this.processStepKey })
            this.updateOptiontNextStep(oldStepKey, this.processStepKey)

            this.setState({ modalOpen: false })
          }}
          okText='Submit'
          okButtonProps={this.getButtonProps()}
          visible={this.state.modalOpen}
          destroyOnClose
          maskClosable={false}
        >
          <Input
            name='change_process_key'
            defaultValue={this.props.entry.key}
            onChange={(e) => {
              this.onProcessStepKeyChange(e.target.value)
            }}
          />
          <span style={{ margin: 5, padding: 5, color: 'red' }}>{this.state.errorText}</span>
        </Modal>
      </Row>
    )
  }
}

export default ProcessStepForm
