import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Globals from '../../../utils/globals'
import MemoryStore from '../../../utils/memory-store'
import { Row, Col, Form, Input, Switch, message } from 'antd'
import Description from '../../../reusables/components/description'
import Notes from '../../../reusables/components/notes'

const { TextArea } = Input

class BotBuilderFormGeneral extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: this.entry,
      isNewDoc: this.entry.custom.isNewDoc,
      urlEndpoint: this.entry.data.urlRouteName === '' ? '' : Globals.config.botServerUrl + '/' + MemoryStore.userProfile.teamId + '/' + this.entry.data.urlRouteName
    }
  }

  render () {
    return (
      <Row type='flex' justify='space-between'>
        <Col xs={24} sm={24} md={14} lg={14}>
          <Form.Item>
            <Switch
              checkedChildren='Active'
              unCheckedChildren='Inactive'
              defaultChecked={this.entry.data.isActive}
              onChange={e => {
                this.props.onChange('isActive', e)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>Profile Key
            <Input
              placeholder='Provide a unique Profile Key'
              disabled={!this.state.isNewDoc}
              defaultValue={this.entry.data.key}
              required
              onChange={e => {
                this.props.onChange('key', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>Profile Name
            <Input
              placeholder='Provide a Profile Name'
              required
              defaultValue={this.entry.data.name}
              onChange={e => {
                this.props.onChange('name', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>Microsoft Bot App Id
            <Input
              placeholder='Provide the App Id for your MS Bot'
              required
              defaultValue={this.entry.data.msAppId}
              onChange={e => {
                this.props.onChange('msAppId', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>Microsoft Bot App Password
            <Input
              placeholder='Provide the App Password for your MS Bot'
              required
              type='password'
              defaultValue={this.entry.data.msAppPwd}
              onChange={e => {
                this.props.onChange('msAppPwd', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>Endpoint URL Route Name
            <Input
              placeholder='e.g. myroute1 or mypath/myroute1'
              defaultValue={this.entry.data.urlRouteName}
              required
              onChange={e => {
                this.props.onChange('urlRouteName', e.target.value)
                // eslint-disable-next-line
                this.state.entry.data.urlRouteName = e.target.value
                this.setState({
                  urlEndpoint:
                    Globals.config.botServerUrl +
                    '/' +
                    MemoryStore.userProfile.teamId +
                    '/' +
                    e.target.value
                })
              }}
            />
          </Form.Item>
          {this.state.entry.data.urlRouteName !== '' ? (
            <div>
              <Form.Item style={{ display: 'inline-block', width: 550 }}>
                URL Endpoint
                <Input
                  id='inputUrlEndpoint'
                  readOnly
                  value={this.state.urlEndpoint}
                />
              </Form.Item>
              <div
                style={{
                  marginTop: 46,
                  marginRight: 10,
                  float: 'right',
                  cursor: 'pointer',
                  display: 'inline-block'
                }}
              >
                <CopyToClipboard
                  text={this.state.urlEndpoint}
                  onCopy={() => message.info('URL Endpoint copied to clipboard')}
                >
                  <span>
                    <FontAwesomeIcon name='copy' style={{ fontSize: 16 }} />
                  </span>
                </CopyToClipboard>
              </div>
            </div>
          ) : null}
          <Form.Item>
            LUIS App URL (optional)
            <TextArea
              placeholder='LUIS endpoint URL'
              defaultValue={this.entry.data.luisAppUrl}
              rows={3}
              onChange={e => {
                this.props.onChange('luisAppUrl', e.target.value)
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={9} lg={9} style={{ marginTop: 53 }}>
          <Description
            description={this.entry.data.description}
            onChange={e => {
              this.props.onChange('description', e.target.value)
            }}
          />
          <Form.Item>
            Group Name (optional)
            <Input
              placeholder='Used to group profiles'
              defaultValue={this.entry.data.groupName}
              onChange={e => {
                this.props.onChange('groupName', e.target.value)
              }}
            />
          </Form.Item>
          <Notes
            notes={this.entry.data.notes}
            onChange={e => {
              this.props.onChange('notes', e.target.value)
            }}
          />
        </Col>
      </Row>
    )
  }
}

export default BotBuilderFormGeneral
