import React from 'react'
import MemoryStore from '../../../../../utils/memory-store'
import { Input, Form, Row, Col, Select } from 'antd'

import TableKeyValueEditableDND from '../../../../../reusables/components/table-key-value-editable-dnd'
import CompileTemplateIcon from '../../../../../reusables/components/compile-template-icon'
import TableKeyTypeValueEditableDnd from '../../../../../reusables/components/table-key-type-value-editable-dnd'
import Enums from '../../../../../utils/enums'

const { TextArea } = Input

class WebApiRouteMain extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.onFieldChange = this.onFieldChange.bind(this)
    this.updateQueryParams = this.updateQueryParams.bind(this)
    this.updateHeaderParams = this.updateHeaderParams.bind(this)
    this.updateFormDataParams = this.updateFormDataParams.bind(this)

    this.selectOptions = [
      { key: 'text', value: 'Text' },
      { key: 'base64', value: 'File - Base64' },
      { key: 'buffer', value: 'File - Buffer' }
    ]

    this.state = {
      requestType: this.props.entry.routeData.requestType ? this.props.entry.routeData.requestType : 'get',
      bodyType: this.props.entry.routeData.bodyType ? this.props.entry.routeData.bodyType : '',
      selectOptions: null
    }
  }

  UNSAFE_componentWillMount() {
    this.updateSelectOptions()
  }

  updateSelectOptions() {
    let tmpOptions = null

    tmpOptions = this.selectOptions.map(entry => {
      return (
        <Select.Option key={entry.key} value={entry.key}>
          {entry.value}
        </Select.Option>
      )
    })

    this.setState({
      selectOptions: tmpOptions
    })
  }

  onFieldChange(key, value) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData[key] = value.trim()
  }

  updateQueryParams(data) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData.queryParams = data
  }

  updateHeaderParams(data) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData.headerParams = data
  }

  updateFormDataParams(data) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.routeData.formDataParams = data
  }

  generateBodyInput() {
    if (this.state.requestType === 'post' || this.state.requestType === 'put' || this.state.requestType === 'patch') {
      switch (this.props.entry.routeData.bodyType) {
        case 'binary':
          return (
            <>
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>
                {'File Id '}
                <CompileTemplateIcon />
                <Input
                  placeholder='File Id'
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  defaultValue={this.props.entry.routeData.body}
                  onChange={e => {
                    this.onFieldChange('body', e.target.value)
                  }}
                />
              </Form.Item>
            </>
          )
        case 'raw':
          return (
            <Form.Item>
              {'Request Body (optional) '}
              <CompileTemplateIcon />
              <TextArea
                className='body'
                placeholder='Add Body Content'
                disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                rows={15}
                defaultValue={this.props.entry.routeData.body}
                onChange={e => {
                  this.onFieldChange('body', e.target.value)
                }}
              />
            </Form.Item>
          )
        case 'formData':
          return (
            <>
              <h3>
                Form Data <CompileTemplateIcon />
              </h3>
              <Form.Item>
                <TableKeyTypeValueEditableDnd
                  values={
                    this.props.entry.routeData.formDataParams
                      ? this.props.entry.routeData.formDataParams
                      : [{ paramKey: '', paramValue: '', paramType: '' }]
                  }
                  selectOptions={this.state.selectOptions}
                  defaultTypeValue='Text'
                  columnTitle='Key'
                  theme={this.props.theme}
                  privileges={MemoryStore.userProfile.teamPrivileges.connectors}
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  callback={data => this.updateFormDataParams(data)}
                />
              </Form.Item>
            </>
          )
        default:
          break
      }
    } else {
      return null
    }
  }

  render() {
    return (
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <h3>Route Info</h3>
          <Row>
            <Col xs={24} xl={16}>
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>Request Type
                <Select
                  className='request_type'
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  defaultValue={this.state.requestType}
                  required
                  onChange={value => {
                    this.onFieldChange('requestType', value)
                    this.setState({ requestType: value })
                  }}>
                  <Select.Option value='get'>GET</Select.Option>
                  <Select.Option value='post'>POST</Select.Option>
                  <Select.Option value='put'>PUT</Select.Option>
                  <Select.Option value='patch'>PATCH</Select.Option>
                  <Select.Option value='delete'>DELETE</Select.Option>
                  {this.props.mainEntry.data.connectionData.authType !== Enums.VALUES_STRINGS.MS_GRAPH_BEARER ? (
                    <>
                      <Select.Option value='copy'>COPY</Select.Option>
                      <Select.Option value='head'>HEAD</Select.Option>
                      <Select.Option value='options'>OPTIONS</Select.Option>
                      <Select.Option value='link'>LINK</Select.Option>
                      <Select.Option value='unlink'>UNLINK</Select.Option>
                      <Select.Option value='purge'>PURGE</Select.Option>
                      <Select.Option value='lock'>LOCK</Select.Option>
                      <Select.Option value='unlock'>UNLOCK</Select.Option>
                      <Select.Option value='propfind'>PROPFIND</Select.Option>
                      <Select.Option value='view'>VIEW</Select.Option>
                    </>
                  ) : null}
                </Select>
              </Form.Item>
              {this.state.requestType === 'post' || this.state.requestType === 'put' || this.state.requestType === 'patch' ? (
                <div style={{ marginTop: 5 }}>
                  <Form.Item>
                    <span style={{ color: 'red' }}>* </span>Body Type
                    <Select
                      className='body_type'
                      disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                      defaultValue={this.state.bodyType}
                      required
                      onChange={value => {
                        this.onFieldChange('bodyType', value)
                        this.setState({ bodyType: value })
                      }}>
                      <Select.Option value=''>-Select-</Select.Option>
                      <Select.Option value='raw'>Raw</Select.Option>
                      <Select.Option value='binary'>Binary</Select.Option>
                      <Select.Option value='formData'>Form Data</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              ) : null}
              <Form.Item
                style={{
                  display: this.props.mainEntry.data.connectionData.authType === Enums.VALUES_STRINGS.MS_GRAPH_BEARER ? 'none' : 'block'
                }}>
                Response Type
                <Select
                  className='response_type'
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  defaultValue={this.props.entry.routeData.responseType ? this.props.entry.routeData.responseType : 'json'}
                  onChange={value => {
                    this.onFieldChange('responseType', value)
                  }}>
                  <Select.Option value='json'>JSON</Select.Option>
                  <Select.Option value='arraybuffer'>Array Buffer</Select.Option>
                  <Select.Option value='blob'>Blob</Select.Option>
                  <Select.Option value='document'>Document</Select.Option>
                  <Select.Option value='text'>Text</Select.Option>
                  <Select.Option value='stream'>Stream</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item>
                {'Web API Route URL '}
                <CompileTemplateIcon />
                <Input
                  name='url'
                  placeholder={
                    this.props.mainEntry.data.connectionData.authType === Enums.VALUES_STRINGS.MS_GRAPH_BEARER
                      ? 'e.g. /v1.0/me/onenote/notebooks OR /v1.0/me/sendMail (Note: Prefix URL with /)'
                      : 'e.g. /data/process (Note: Prefix URL with /)'
                  }
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  defaultValue={this.props.entry.routeData.routePath}
                  required
                  onChange={e => {
                    this.onFieldChange('routePath', e.target.value)
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <h3>
                Query Parameters <CompileTemplateIcon />
              </h3>
              <Form.Item>
                <TableKeyValueEditableDND
                  values={this.props.entry.routeData.queryParams ? this.props.entry.routeData.queryParams : [{ paramKey: '', paramValue: '' }]}
                  columnTitle='Key'
                  theme={this.props.theme}
                  privileges={MemoryStore.userProfile.teamPrivileges.connectors}
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  callback={data => this.updateQueryParams(data)}
                  fields={['paramKey', 'paramValue']}
                />
              </Form.Item>
              <h3>
                Header Parameters <CompileTemplateIcon />
              </h3>
              <Form.Item>
                <TableKeyValueEditableDND
                  values={this.props.entry.routeData.headerParams ? this.props.entry.routeData.headerParams : [{ paramKey: '', paramValue: '' }]}
                  columnTitle='Key'
                  theme={this.props.theme}
                  privileges={MemoryStore.userProfile.teamPrivileges.connectors}
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  callback={data => this.updateHeaderParams(data)}
                  fields={['paramKey', 'paramValue']}
                />
              </Form.Item>
              {this.generateBodyInput()}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default WebApiRouteMain
