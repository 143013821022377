import { connect } from 'react-redux'
import BPMForm from '../components/bpm-form/bpm-form'
import MemoryStore from '../../utils/memory-store'

import { saveProfile } from '../bpm-actions'
import { closeTab, fetchAllData } from '../../core/core-actions'
import { clearHistoryData, getRoleProfiles, saveRoleProfile } from '../bpm-utils'
import Enums from '../../utils/enums'

const mapStateToProps = (state) => {
  if (!state[state.core.tabObject.activeApp]) return {}

  const appId = state.core.tabObject.activeApp
  const tabKey = state.core.tabObject.activeKey
  const tabs = state.core.tabObject.tabs
  const entry = MemoryStore.activeEntries[tabKey]
  let title = Enums.VALUES_STRINGS.NEW_PROPER_CASE + Enums.VALUES_STRINGS.SPACE + Enums.APP_PROFILE_TITLES[appId]

  if (entry && entry.data && !entry.custom.isNewDoc) {
    title = Enums.APP_PROFILE_TITLES[appId] + Enums.VALUES_STRINGS.COLON + Enums.VALUES_STRINGS.SPACE + entry.data.key
  }

  return {
    appId,
    tabKey,
    tabs,
    title,
    state: state[appId],
    theme: state.core.theme
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllData: (appId, callback) => {
      dispatch(fetchAllData(appId, callback))
    },
    closeTab: (activeKey, targetKey, tabs) => {
      dispatch(closeTab(activeKey, targetKey, tabs))
    },
    saveProfile: (appId, activeKey, state, updateStubs, callback) => {
      dispatch(saveProfile(appId, activeKey, state, updateStubs, callback))
    },
    onClearHistoryData: (key) => {
      clearHistoryData(key)
    },
    onGetRoleProfiles: () => {
      getRoleProfiles()
    },
    onSaveRoleProfile: (data, callback) => {
      saveRoleProfile(data, callback)
    }
  }
}

const BPMFormContainer = connect(mapStateToProps, mapDispatchToProps)(BPMForm)

export default BPMFormContainer
