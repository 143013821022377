import React from 'react'
import { Input, Select, Col, Form, Divider } from 'antd'
import MemoryStore from '../../utils/memory-store'
import AgiliteTheme from '../../utils/agilite-theme'

class TeamUsersFormGeneral extends React.Component {
  constructor(props) {
    super(props)

    this.state = { isNewDoc: this.props.entry.custom.isNewDoc }
  }

  render() {
    return (
      <Col xs={24} lg={11}>
        <h3>General</h3>
        <Divider />
        <h3 style={this.props.entry.teamRights.status === 'Active' ? { color: AgiliteTheme.successColor } : { color: AgiliteTheme.dangerColor }}>
          {this.props.entry.teamRights.status}
        </h3>
        <Form.Item>
          <span style={{ color: 'red' }}>* </span>First Name
          <Input
            disabled={!this.state.isNewDoc || MemoryStore.userProfile.teamPrivileges.teams === 'Reader'}
            value={this.props.entry.firstName}
            required
            onChange={e => {
              this.props.onFieldChange('firstName', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          <span style={{ color: 'red' }}>* </span>Last Name
          <Input
            disabled={!this.state.isNewDoc || MemoryStore.userProfile.teamPrivileges.teams === 'Reader'}
            required
            value={this.props.entry.lastName}
            onChange={e => {
              this.props.onFieldChange('lastName', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          <span style={{ color: 'red' }}>* </span>Email
          <Input
            type='email'
            disabled={!this.state.isNewDoc || MemoryStore.userProfile.teamPrivileges.teams === 'Reader'}
            required
            value={this.props.entry.email}
            onChange={e => {
              this.props.onFieldChange('email', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item>
          Role
          <Select
            disabled={MemoryStore.userProfile.teamPrivileges.teams === 'Reader'}
            value={this.props.entry.teamRights.role}
            onChange={value => {
              this.props.onFieldChange('role', value)
            }}>
            <Select.Option value='Admin'>Admin</Select.Option>
            <Select.Option value='User'>User</Select.Option>
          </Select>
        </Form.Item>
      </Col>
    )
  }
}

export default TeamUsersFormGeneral
