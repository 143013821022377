import React from 'react'
import { Card, Row, Form, Input, Tabs, Switch, Tooltip, Modal, AutoComplete, Col, Dropdown, Menu, Popconfirm } from 'antd'
import { TranslationOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

import MemoryStore from '../../../utils/memory-store'

/*
  Custom Components Import
*/
import SingleValueTableEditableDND from '../../../reusables/components/single-value-table-editable-dnd'
import VisibleObjectsTableEditableDND from '../../../reusables/components/visible-objects-table-editable-dnd'
import TooltipIcon from '../../../reusables/components/tooltip-icon'
import CompileTemplateIcon from '../../../reusables/components/compile-template-icon'
import EnumsTooltips from '../../../utils/enums-tooltips'
import Description from '../../../reusables/components/description'
import Notes from '../../../reusables/components/notes'
import LanguageTranslation from '../../../reusables/components/language-translation'
import TableKeyValueEditableDND from '../../../reusables/components/table-key-value-editable-dnd'

const TabPane = Tabs.TabPane
const { Option } = AutoComplete

class StepOptionForm extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]
    this.optionKey = ''

    this.iln = this.props.entry.iln ? this.props.entry.iln : {}

    let nextStep = this.props.entry.nextStep

    for (const x in props.stepList) {
      if (nextStep === props.stepList[x].value) {
        nextStep = props.stepList[x].label
        break
      }
    }

    this.state = {
      entry: this.props.entry,
      nextStep,
      languageModalVisible: false,
      dataKey: null,
      modalOpen: false,
      errorText: '',
      btnDisabled: false,
      key: this.props.entry.key
    }

    // Setup Event Binding
    this.handleOnChange = this.handleOnChange.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.updateEventStamps = this.updateEventStamps.bind(this)
    this.updateVisibleObjects = this.updateVisibleObjects.bind(this)
    this.handleDataChange = this.handleDataChange.bind(this)
    this.handleDataSubmit = this.handleDataSubmit.bind(this)
    this.updateKeywords = this.updateKeywords.bind(this)

    if (props.entry.eventStamp.length === 0) {
      props.entry.eventStamp.push({
        value: ''
      })
    }
  }

  onFieldChange (key, value, option) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry[key] = value

    if (key === 'nextStep') this.setState({ nextStep: option.children })
    if (key === 'key') this.setState({ key: value })
  }

  handleOnChange (key, value, index) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry[key][index] = { value }
  }

  updateEventStamps (data) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.eventStamp = data
  }

  updateVisibleObjects (data) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.visibleObjects = data
  }

  handleDataChange (data) {
    this.iln[this.state.dataKey] = data
  }

  handleDataSubmit () {
    this.setState({ languageModalVisible: false })
    this.onFieldChange('iln', this.iln)
  }

  updateKeywords (result) {
    this.entry.custom.isModifiedSubform = true
    this.props.entry.keywords = result
  }

  getButtonProps () {
    if (this.state.btnDisabled) {
      return {
        disabled: this.state.btnDisabled
      }
    } else {
      return {
        disabled: this.state.btnDisabled,
        style: {
          backgroundColor: '#67AD5B',
          borderColor: '#67AD5B'
        }
      }
    }
  }

  onOptionKeyChange (value) {
    this.optionKey = value

    if (this.optionKey === this.props.entry.key) {
      this.setState({
        errorText: 'New Step Option Key cannot be the same as existing Step Option Key',
        btnDisabled: true
      })
    } else if (!this.optionKey.trim()) {
      this.setState({
        errorText: 'Step Option Key cannot be empty',
        btnDisabled: true
      })
    } else {
      this.setState({ errorText: '', btnDisabled: false })
    }
  }

  render () {
    const menu = (
      <Menu>
        <Menu.Item key='0'>
          <Popconfirm
            title='Are you sure you want to change the Step Option Key?'
            onConfirm={() => {
              this.optionKey = this.props.entry.key
              this.setState({ modalOpen: true })
              this.onOptionKeyChange(this.props.entry.key)
            }}
            okText='Yes'
            cancelText='No'
            placement='left'
          >
            <span style={{ padding: '10 0' }}>Edit Option Key</span>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    )

    return (
      <Row>
        <Col span={24}>
          <Card
            extra={!this.props.entry.isNewEntry ?
              <Dropdown overlay={menu} trigger={['click']}>
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  style={{
                    padding: 5,
                    fontSize: 17,
                    color: '#B4483E',
                    cursor: 'pointer',
                    margin: -10
                  }}
                />
              </Dropdown>
              : null}
          >
            <Form>
              <Tabs animated={false} defaultActiveKey='1'>
                <TabPane tab='General' key='1'>
                  <Form.Item>
                    <Switch
                      disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                      checkedChildren='Active'
                      unCheckedChildren='Inactive'
                      defaultChecked={this.props.entry.isActive}
                      onChange={e => {
                        this.onFieldChange('isActive', e)
                      }}
                    />
                  </Form.Item>
                  <Form.Item style={{ width: '50%' }}>
                    <span style={{ color: 'red' }}>* </span>
                    {'Option Key '}
                    <TooltipIcon title={EnumsTooltips.general.profileKey} />
                    <Input
                      name='option_key'
                      placeholder='Provide an Option Key'
                      disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader' || !this.props.isNewDoc}
                      value={this.props.entry.key}
                      onChange={e => {
                        this.onFieldChange('key', e.target.value)
                      }}
                    />
                  </Form.Item>
                  <Form.Item style={{ width: '50%' }}>
                    <span style={{ color: 'red' }}>* </span>
                    {'Option Name '}
                    <TooltipIcon title={EnumsTooltips.general.profileName} />
                    <Tooltip title='Add language alternatives for field value'>
                      <TranslationOutlined
                        onClick={() => this.setState({ languageModalVisible: true, dataKey: 'name' })}
                        style={{ color: 'blueviolet', fontSize: 16, marginLeft: 8 }}
                      />
                    </Tooltip>
                    <Input
                      name='option_name'
                      placeholder='Provide an Option Name'
                      disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                      defaultValue={this.props.entry.name}
                      onChange={e => {
                        this.onFieldChange('name', e.target.value)
                        this.iln.name = {
                          ...this.iln.name,
                          [MemoryStore.defaultLanguage]: e.target.value
                        }
                      }}
                    />
                  </Form.Item>
                  <Description
                    disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                    description={this.props.entry.description}
                    onChange={e => {
                      this.onFieldChange('description', e.target.value)
                      this.iln.description = {
                        ...this.iln.description,
                        [MemoryStore.defaultLanguage]: e.target.value
                      }
                    }}
                    icon={
                      <Tooltip title='Add language alternatives for field value'>
                        <TranslationOutlined
                          onClick={() => this.setState({ languageModalVisible: true, dataKey: 'description' })}
                          style={{ color: 'blueviolet', fontSize: 16, marginLeft: 8 }}
                        />
                      </Tooltip>
                    }
                  />
                  <Form.Item style={{ marginTop: 20 }}>
                    <SingleValueTableEditableDND
                      data={this.props.entry.eventStamp}
                      disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                      title='Event Stamps (optional)'
                      theme={this.props.theme}
                      onChange={this.handleOnChange}
                      callback={this.updateEventStamps}
                      arrayKey='eventStamp'
                    />
                  </Form.Item>
                  {/*
                    This Form Item is placed inside a div with a specific id
                    so that the AutoComplete popup doesn't move on scroll
                  */}
                  <div id='form_item' style={{ position: 'relative' }}>
                    <Form.Item style={{ width: '50%' }}>
                      <span style={{ color: 'red' }}>* </span>
                      {'Next Process Step Key '}
                      <CompileTemplateIcon />
                      <AutoComplete
                        getPopupContainer={() => document.getElementById('form_item')}
                        placeholder='Please select a next Step to navigate to'
                        disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                        value={this.state.nextStep}
                        onSelect={(value, option) => {
                          this.onFieldChange('nextStep', value, option)
                        }}
                        onChange={(value, option) => {
                          this.onFieldChange('nextStep', value, option)
                        }}
                      >
                        {this.props.stepList.map(step => {
                          return <Option key={step.value} value={step.value}>{step.label}</Option>
                        })}
                      </AutoComplete>
                    </Form.Item>
                  </div>
                  <Notes
                    disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                    notes={this.props.entry.notes}
                    onChange={e => {
                      this.onFieldChange('notes', e.target.value)
                      this.iln.notes = {
                        ...this.iln.notes,
                        [MemoryStore.defaultLanguage]: e.target.value
                      }
                    }}
                    icon={
                      <Tooltip title='Add language alternatives for field value'>
                        <TranslationOutlined
                          onClick={() => this.setState({ languageModalVisible: true, dataKey: 'notes' })}
                          style={{ color: 'blueviolet', fontSize: 16, marginLeft: 8 }}
                        />
                      </Tooltip>
                    }
                  />
                </TabPane>
                <TabPane tab='Object Rules' key='2'>
                  <div>
                    <VisibleObjectsTableEditableDND
                      entry={this.props.entry}
                      theme={this.props.theme}
                      title='Visible Objects'
                      disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                      callback={this.updateVisibleObjects}
                    />
                  </div>
                </TabPane>
                <TabPane tab='Keywords' key='3'>
                  <TableKeyValueEditableDND
                    columnTitle='Key'
                    values={this.props.entry.keywords ? this.props.entry.keywords : []}
                    theme={this.props.theme}
                    privileges={MemoryStore.userProfile.teamPrivileges.bpm}
                    disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                    callback={this.updateKeywords}
                  />
                </TabPane>
              </Tabs>
            </Form>
          </Card>
          <Modal
            title='Language Translate'
            visible={this.state.languageModalVisible}
            onOk={this.handleDataSubmit}
            onCancel={() => this.setState({ languageModalVisible: false })}
            closable={false}
            width='50%'
            destroyOnClose
            okButtonProps={{
              style: {
                backgroundColor: '#67AD5B',
                color: 'white'
              }
            }}
            okText='Submit'
          >
            <LanguageTranslation
              theme={this.props.theme}
              disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
              data={this.iln[this.state.dataKey]}
              dataKey={this.state.dataKey}
              defaultLanguage={MemoryStore.defaultLanguage}
              privileges={MemoryStore.userProfile.teamPrivileges.bpm}
              fieldValue={this.props.entry[this.state.dataKey]}
              onDataChange={this.handleDataChange}
            />
          </Modal>
          <Modal
            title='Change Step Option Key'
            onCancel={() => this.setState({ modalOpen: false })}
            onOk={() => {
              this.entry.custom.isModifiedSubform = true
              this.props.entry.key = this.optionKey

              this.setState({ modalOpen: false })
            }}
            okText='Submit'
            okButtonProps={this.getButtonProps()}
            visible={this.state.modalOpen}
            destroyOnClose
            maskClosable={false}
          >
            <Input
              name='change_step_key'
              defaultValue={this.props.entry.key}
              onChange={(e) => {
                this.onOptionKeyChange(e.target.value)
              }}
            />
            <span style={{ margin: 5, padding: 5, color: 'red' }}>{this.state.errorText}</span>
          </Modal>
        </Col>
      </Row>
    )
  }
}

export default StepOptionForm
