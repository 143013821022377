import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import MemoryStore from '../../../../utils/memory-store'
import { getFile } from '../../../../core/core-utils'
import { useDropzone } from 'react-dropzone'
import { Input, Row, Col, Select, Table, Form, Popconfirm, message, Spin } from 'antd'
import CompileTemplateIcon from '../../../../reusables/components/compile-template-icon'
import configuration from '../../../../utils/config.json'
import Enums from '../../../../utils/enums'
import Axios from 'agilite-utils/axios'

const DestinationDetailsExcel = (props) => {
  const entry = MemoryStore.activeEntries[props.tabKey] ? MemoryStore.activeEntries[props.tabKey] : JSON.parse(JSON.stringify(MemoryStore.datamappings.dataTemplate))

  const [dropzoneActive, setDropzoneActive] = useState(entry.data.desExcel.attachments.length === 0)
  const [spinEnabled, setSpinEnabled] = useState(false)
  const [desType, setDesType] = useState(entry.data.desExcel.desType)

  const onUpload = (fileName, contentType, data) => {
    setSpinEnabled(true)

    Axios.request({
      baseURL: `${configuration.apiServerUrl}/files`,
      method: Enums.REQ_TYPE.POST,
      headers: {
        'persist-file': true,
        'api-key': MemoryStore.apiKey,
        'team-name': MemoryStore.userProfile.teamId,
        'file-name': fileName,
        'Content-Type': contentType
      },
      data
    })
      .then(res => {
        onAttachmentUploaded(res)
        setSpinEnabled(false)
      })
      .catch(err => {
        setSpinEnabled(false)

        if (err.response) {
          message.error(err.response.data.errorMessage)
        } else {
          message.error(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      })
  }

  const Dropzone = ({ handleAttachmentUpload, tmpThis }) => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: '.xls, .xlsx',
      multiple: false,
      maxSize: configuration.dropZoneConfig.import.maxFileSize,
      onDrop: files => {
        // eslint-disable-next-line
        const reader = new FileReader()

        reader.onabort = () => message.error('File reading was aborted')
        reader.onerror = () => message.error('File reading has failed')
        reader.onload = () => {
          handleAttachmentUpload(files[0].name, Enums.VALUES_STRINGS.APPLICATION_OCTET_STREAM, reader.result)
        }
        if (files.length > 0) {
          reader.readAsArrayBuffer(files[0])
        }
      }
    })

    return (
      <section>
        <div {...getRootProps()} className='dropzone'>
          <input {...getInputProps()} />
          {spinEnabled ? <Spin spinning={spinEnabled} /> : <p>Click here or drop Excel file to upload</p>}
        </div>
      </section>
    )
  }

  const onChange = (key, value) => {
    entry.custom.isModified = true
    entry.data.desExcel[key] = value

    if (key === 'desType') setDesType(value)
  }

  const onAttachmentUploaded = (res) => {
    entry.custom.isModified = true

    if (res) {
      setTimeout(() => {
        const tmpEntry = {
          _id: res.data._id,
          fileName: res.data.filename,
          key: res.data._id
        }

        entry.data.desExcel.attachments.push(tmpEntry)
        setDropzoneActive(false)
      }, 500)
    }

    return null
  }

  const onAttachmentDelete = (key) => {
    entry.custom.isModified = true

    const tmpAttachments = entry.data.desExcel.attachments
    const index = tmpAttachments.findIndex(t => t._id === key)

    if (index > -1) {
      // Add entry to delete array and remove entry from attachments array. Update State
      const tmpEntry = tmpAttachments[index]
      entry.data.desExcel.deleteAttachments.push(tmpEntry)
      tmpAttachments.splice(index, 1)
      setDropzoneActive(true)
    }

    return null
  }

  const formatAttachmentData = () => {
    const data = entry.data.desExcel.attachments.map(entry => {
      return {
        key: entry._id,
        description: entry.fileName
      }
    })

    return data
  }

  const columns = [
    {
      title: 'Attachments',
      dataIndex: 'attachment',
      key: 'attachments',
      render: (text, record) => {
        return (
          <span
            onClick={() => {
              getFile(record.key, record.description)
            }}
          >
            {/* eslint-disable-next-line */}
            <a>{record.description}</a>
          </span>
        )
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <div className='trash-button'>
            <Popconfirm
              title='Are you sure delete this attachments?'
              onConfirm={() => onAttachmentDelete(record.key)}
              okText='Yes'
              cancelText='No'
            >
              {/* eslint-disable-next-line */}
              <a>
                <FontAwesomeIcon
                  icon={faTrash}
                  className='trash-enabled'
                  style={{ color: props.theme.dangerColor }}
                />
              </a>
            </Popconfirm>
          </div>
        )
      }
    }
  ]

  return (
    <Row type='flex' justify='space-between'>
      <Col xs={24} sm={24} md={11} lg={11}>
        <h4>Excel Details</h4>
        <Form.Item>
          Output Type
          <Select
            className='output_type'
            disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
            defaultValue={entry.data.desExcel.desType}
            onChange={value => {
              onChange('desType', value)
            }}
          >
            <Select.Option value='1'>Create Excel File</Select.Option>
            <Select.Option value='3'>Attach Excel File</Select.Option>
          </Select>
        </Form.Item>
        {desType === '1' ? (
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            {'File Name '}
            <CompileTemplateIcon />
            <Input
              cypressid='fileName'
              name='file_name'
              disabled={MemoryStore.userProfile.teamPrivileges.datamappings === 'Reader'}
              placeholder='Provide a file name for the new Excel Worksheet'
              defaultValue={entry.data.desExcel.fileName}
              onChange={e => {
                onChange('fileName', e.target.value)
              }}
            />
          </Form.Item>
        ) : null}
      </Col>
      {desType === '3' ? (
        <Col xs={24} md={12} style={{ marginTop: 20 }}>
          {dropzoneActive ? (
            <div style={{ zIndex: 1, position: 'relative' }}>
              <Dropzone tmpThis={this} handleAttachmentUpload={onUpload} />
            </div>
          ) : (
            <Table
              bordered
              pagination={false}
              columns={columns}
              dataSource={formatAttachmentData()}
            />
          )}
        </Col>
      ) : null}
    </Row>
  )
}

export default DestinationDetailsExcel
