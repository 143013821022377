import MemoryStore from '../utils/memory-store'
import { cleanUpTableData, formatRecordData } from '../utils/utilities'
import Enums from '../utils/enums'
import rolesFormContainer from '../roles/containers/roles-form-container'

export const filterViewData = (viewData, searchFilter) => {
  searchFilter = searchFilter.toLowerCase()

  if (searchFilter === '') {
    return viewData
  } else {
    return viewData.filter(t =>
      (
        (t.data.name.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.tmpResponsibleUser.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.tmpLevels.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.groupName.toLowerCase().indexOf(searchFilter) > -1) ||
        (t.data.description.toLowerCase().indexOf(searchFilter) > -1)
      )
    )
  }
}

export const changeConLevels = (id, levels, callback) => {
  const tmpLevels = []
  let errMsg = null

  for (const entry of levels) {
    tmpLevels.push(entry.value)
  }

  MemoryStore.agilite.Roles.changeConditionalLevels(id, tmpLevels)
    .then(function (response) {
      callback(null, response)
    })
    .catch(function (error) {
      if (error.response) {
        errMsg = error.response
      } else {
        errMsg = 'Unknown Error Occurred'
      }

      callback(errMsg)
    })
}

export const reAssignResponsibleUser = (id, responsibleUser, callback) => {
  let errMsg = null

  MemoryStore.agilite.Roles.reAssignResponsibleUser(id, responsibleUser)
    .then(function (response) {
      callback(null, response)
    })
    .catch(function (error) {
      if (error.response) {
        errMsg = error.response
      } else {
        errMsg = 'Unknown Error Occurred'
      }

      callback(errMsg)
    })
}

export const validateConLevels = (id, conLevels, callback) => {
  const levels = cleanUpTableData(conLevels, false)
  changeConLevels(id, levels, callback)
}

export const formatRecord = (entry, data) => {
  let tempIndex = 0

  entry = formatRecordData(entry)
  entry.data.responsibleUser = entry.data.tmpResponsibleUser.trim().split(',')

  // Setup Fields for Table
  if (!entry.key) {
    entry.key = entry._id
  }

  if (entry.data && entry.data.isActive !== undefined) {
    entry.data.isActiveDescription = entry.data.isActive ? 'Yes' : 'No'
  }

  entry.data.tmpLevels = entry.data.levels.map(level => level.value)
  entry.data.tmpLevels = entry.data.tmpLevels.length > 0 ? entry.data.tmpLevels.join(' - ') : ''

  if (entry.custom.isNewDoc) {
    // Add new Entry to State
    entry.custom.isNewDoc = false
    entry.custom.action = 'create'
    data.push(entry)
  } else {
    if (entry.custom.isSavedDoc) {
      entry.custom.action = 'update'
    } else {
      entry.custom.action = 'create'
    }

    // Update Entry in State
    tempIndex = data.findIndex(t => t._id === entry._id)
    data[tempIndex] = entry
  }

  return data
}

export const createCopy = (currEntryData, callback) => {
  const newEntry = JSON.parse(JSON.stringify(MemoryStore.roles.dataTemplate))
  newEntry._id = new Date().getTime().toString()
  newEntry.custom.tempId = newEntry._id

  const entryData = JSON.parse(JSON.stringify(currEntryData))
  const tabType = Enums.VALUES_STRINGS.FORM
  const content = rolesFormContainer
  const closable = true
  const key = newEntry._id
  const app = Enums.APP_IDS.ROLES

  let title = Enums.APP_PROFILE_TITLES.roles + Enums.VALUES_STRINGS.COLON + Enums.VALUES_STRINGS.SPACE
  let payload = {}

  // create unique key
  entryData.name = currEntryData.name + '_copy'

  newEntry.data = entryData

  title += entryData.key

  payload = {
    content,
    title,
    key,
    app,
    tabType,
    closable
  }

  MemoryStore.activeEntries[newEntry._id] = newEntry

  callback(payload)
}
