import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import MemoryStore from '../../utils/memory-store'
import { Form, Row, Button, Card, message, Dropdown, Menu, Col, Popconfirm, Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import APIKeysFormGeneral from './api-keys-form-general'
import APIKeysFormPrivileges from './api-keys-form-privileges'
import Enums from '../../utils/enums'

class APIKeysForm extends React.Component {
  constructor (props) {
    super(props)

    this.entry = MemoryStore.activeEntries[props.tabKey]

    this.state = {
      isNewDoc: this.entry.custom.isNewDoc,
      errorText: '',
      entry: Object.assign({}, this.entry),
      isLoading: false
    }

    // Setup Event Binding
    this.handleResetPromptConfirm = this.handleResetPromptConfirm.bind(this)
    this.handleEnableAPIKey = this.handleEnableAPIKey.bind(this)
    this.handleDisableAPIKey = this.handleDisableAPIKey.bind(this)
    this.cancelProfileConfirm = this.cancelProfileConfirm.bind(this)
    this.cancelProfile = this.cancelProfile.bind(this)
    this.saveProfile = this.saveProfile.bind(this)
    this.setPrivileges = this.setPrivileges.bind(this)
    this.handleGeneralChange = this.handleGeneralChange.bind(this)
    this.handlePrivilegesChange = this.handlePrivilegesChange.bind(this)
  }

  handleResetPromptConfirm () {
    const msg = 'API Keys successfully reset'
    const tmpThis = this

    tmpThis.setState({ promptOpen: false, promptId: '' })

    tmpThis.props.onResetApiKeys(this.entry._id, (err, result) => {
      if (err) {
        if (err.response) {
          message.error(err.response.data.errorMessage)
        } else {
          message.error(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      } else {
        message.success(msg)
      }
    })
  }

  handleDisableAPIKey () {
    const tmpThis = this
    const msg = 'API Key Successfully Disabled'

    tmpThis.props.onDisableApiKey(tmpThis.entry._id, (err, result) => {
      if (err) {
        if (err.response) {
          message.error(err.response.data.errorMessage)
        } else {
          message.error(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      } else {
        tmpThis.entry.teamRights.isActive = false
        tmpThis.entry.teamRights.status = 'Disabled'
        tmpThis.saveProfile()
        message.success(msg)
      }
    })
  }

  handleEnableAPIKey () {
    const tmpThis = this
    const msg = 'API Key Successfully Enabled'

    tmpThis.props.onEnableApiKey(tmpThis.entry._id, (err, result) => {
      if (err) {
        if (err.response) {
          message.error(err.response.data.errorMessage)
        } else {
          message.error(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      } else {
        tmpThis.entry.teamRights.isActive = true
        tmpThis.entry.teamRights.status = 'Active'
        tmpThis.saveProfile()
        message.success(msg)
      }
    })
  }

  cancelProfileConfirm () {
    if (this.entry.custom.isModified) {
      Modal.confirm({
        onOk: () => this.cancelProfile(),
        okText: Enums.VALUES_STRINGS.YES,
        cancelText: Enums.VALUES_STRINGS.NO,
        content: Enums.MESSAGES.CANCEL_CONTENT,
        title: Enums.MESSAGES.CANCEL_TITLE,
        centered: true
      })
    } else {
      this.cancelProfile()
    }
  }

  cancelProfile () {
    this.props.closeTab(this.props.tabKey, this.props.tabKey, this.props.tabs)
  }

  saveProfile () {
    const tmpThis = this

    tmpThis.setState({
      isLoading: true
    })

    tmpThis.props.saveProfile(tmpThis.props.appId, tmpThis.props.tabKey, this.props.state, (err) => {
      tmpThis.setState({
        isLoading: false
      })

      if (err) {
        message.error(err)
      } else {
        // Reset isModified and isModifiedSubform properties
        tmpThis.entry.custom.isModified = false
        tmpThis.entry.custom.isModifiedSubform = false

        tmpThis.props.closeTab(
          tmpThis.props.tabKey,
          tmpThis.props.tabKey,
          tmpThis.props.tabs
        )
      }
    })
  }

  setPrivileges (value) {
    const tmpEntry = Object.assign({}, this.state.entry)

    Object.keys(tmpEntry.teamRights.privileges).forEach(key => {
      if (value === 'Reader') {
        switch (key) {
          case 'importexport':
          case 'dashboards':
            tmpEntry.teamRights.privileges[key] = 'Editor'
            break
          default:
            tmpEntry.teamRights.privileges[key] = value
            break
        }
      } else if (value === 'Editor') {
        tmpEntry.teamRights.privileges[key] = value
      } else {
        tmpEntry.teamRights.privileges[key] = ''
      }
    })

    this.setState({
      entry: tmpEntry
    })
  }

  handlePrivilegesChange (key, value) {
    const tmpEntry = Object.assign({}, this.state.entry)

    this.entry.custom.isModified = true
    this.entry.teamRights.privileges[key] = value
    tmpEntry.teamRights.privileges[key] = value

    this.setState({
      entry: tmpEntry
    })
  }

  handleGeneralChange (key, value) {
    const tmpEntry = Object.assign({}, this.state.entry)

    this.entry.custom.isModified = true
    this.entry[key] = value
    tmpEntry[key] = value

    this.setState({
      entry: tmpEntry
    })
  }

  render () {
    const menu = (
      <Menu>
        <Menu.ItemGroup title='Default Privileges To'>
          <Menu.Item value='3' onClick={() => this.setPrivileges('Editor')}>
            <span style={{ padding: '10 0' }}>Editor</span>
          </Menu.Item>
          <Menu.Item value='4' onClick={() => this.setPrivileges('Reader')}>
            <span style={{ padding: '10 0' }}>Reader</span>
          </Menu.Item>
          <Menu.Item value='5' onClick={() => this.setPrivileges('None')}>
            <span style={{ padding: '10 0' }}>None</span>
          </Menu.Item>
        </Menu.ItemGroup>
        {!this.state.isNewDoc && MemoryStore.userProfile.teamPrivileges.apikeys !== 'Reader' && this.entry.status !== 'Pending' ?
          <Menu.ItemGroup title='Enable/Disable or Reset'>
            {this.entry.teamRights.isActive === false ? (
              <Menu.Item value='2'>
                <Popconfirm
                  title='Are you sure you want to Enable this API Key?'
                  onConfirm={this.handleEnableAPIKey}
                  okText='Yes'
                  cancelText='No'
                >
                  Re-enable API Key
                </Popconfirm>
              </Menu.Item>
            ) : null}
            {this.entry.teamRights.isActive === true ? (
              <Menu.Item value='3'>
                <Popconfirm
                  title='Are you sure you want to Disable this API Key?'
                  onConfirm={this.handleDisableAPIKey}
                  okText='Yes'
                  cancelText='No'
                >
                  Disable API Key
                </Popconfirm>
              </Menu.Item>
            ) : null}
            <Menu.Item value='1'>
              <Popconfirm
                title='Are you sure you want to reset all API Keys for this Profile?'
                onConfirm={this.handleResetPromptConfirm}
                okText='Yes'
                cancelText='No'
              >
                Reset API Keys
              </Popconfirm>
            </Menu.Item>
          </Menu.ItemGroup>
          : null}
      </Menu>
    )

    return (
      <Row type='flex' justify='center'>
        <Col xs={23} lg={20} xl={16}>
          <Card
            style={{ textAlign: 'left' }}
            title={this.props.title}
            extra={
              <Dropdown overlay={menu} trigger={['click']}>
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  style={{
                    padding: 5,
                    fontSize: 17,
                    color: '#B4483E',
                    cursor: 'pointer'
                  }}
                />
              </Dropdown>
            }
            type='inner'
          >
            <Form
              autoComplete='off'
            >
              <Row type='flex' justify='space-between'>
                <APIKeysFormGeneral
                  tabKey={this.props.tabKey}
                  theme={this.props.theme}
                  entry={this.state.entry}
                  onFieldChange={this.handleGeneralChange}
                />
                <APIKeysFormPrivileges
                  tabKey={this.props.tabKey}
                  theme={this.props.theme}
                  entry={this.state.entry}
                  onFieldChange={this.handlePrivilegesChange}
                />
              </Row>
              <Row>
                <div>
                  {MemoryStore.userProfile.teamPrivileges.apikeys !== 'Reader' ?
                    (
                      <Button
                        htmlType='submit'
                        style={{
                          backgroundColor: '#67AD5B',
                          color: 'white',
                          width: 80,
                          marginRight: 10
                        }}
                        onClick={() => this.saveProfile()}
                        disabled={this.state.isLoading}
                      >
                        {this.state.isLoading ? <LoadingOutlined spin /> : <span>Submit</span>}
                      </Button>
                    ) : null}
                  <Button
                    label='Cancel'
                    disabled={this.state.isLoading}
                    style={{
                      backgroundColor: '#EB5181',
                      color: 'white',
                      width: 'auto',
                      marginRight: 10
                    }}
                    onClick={e => {
                      e.preventDefault()
                      this.cancelProfileConfirm()
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default APIKeysForm
