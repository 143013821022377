import { v1 } from 'agilite-utils/uuid'
import MemoryStore from '../utils/memory-store'
import Enums from '../utils/enums'
import Globals from '../utils/globals'

import { actions } from './core-actions'
import { store } from '../index'

// MODULECONDITION: Module Containers
import KeywordsFormContainer from '../keywords/containers/keywords-form-container'
import TierStructureFormContainer from '../tier-structures/containers/tier-structure-form-container'
import NumberingFormContainer from '../numbering/containers/numbering-form-container'
import ConnectorsFormContainer from '../connectors/containers/connectors-form-container'
import TeamUsersFormContainer from '../team-users/containers/team-users-form-container'
import RolesFormContainer from '../roles/containers/roles-form-container'
import ApiKeysFormContainer from '../api-keys/containers/api-keys-form-container'
import TemplatesFormContainer from '../templates/containers/template-form-container'
import DataMappingFormContainer from '../data-mapping/containers/data-mapping-form-container'
import BpmFormContainer from '../bpm/containers/bpm-form-container'
import BotBuilderFormContainer from '../bot-builder/containers/bot-builder-form-container'
import GatewayAdaptersFormContainer from '../gateway-adapters/containers/gateway-adapters-form-container'
import BatchActionsFormContainer from '../batch-actions/containers/batch-actions-form-container'
import EventsFormContainer from '../events/containers/events-form-container'
import BatchLoggingFormContainer from '../batch-logging/containers/batch-logging-form-container'
import NodeRedFormContainer from '../node-red/containers/node-red-form-container'
import SolutionsFormContainer from '../solutions/containers/solutions-form-container'

// MODULECONDITION
import KeywordsListViewContainer from '../keywords/containers/keywords-list-view-container'
import NumberingListViewContainer from '../numbering/containers/numbering-list-view-container'
import ConnectorsListViewContainer from '../connectors/containers/connectors-list-view-container'
import TeamUsersListViewContainer from '../team-users/containers/team-users-list-view-container'
import RolesListViewContainer from '../roles/containers/roles-list-view-container'
import ApiKeysListViewContainer from '../api-keys/containers/api-keys-list-view-container'
import TemplatesListViewContainer from '../templates/containers/template-list-view-container'
import DataMappingListViewContainer from '../data-mapping/containers/data-mapping-list-view-container'
import BpmListViewConatiner from '../bpm/containers/bpm-list-view-container'
import DashboardContainer from '../dashboard/containers/dashboard-container'
import ErrorLogsContainer from '../error-logs/containers/error-logs-list-view-container'
import AdminAccountContainer from '../core/containers/admin/admin-account-container'
import BotBuilderListViewContainer from '../bot-builder/containers/bot-builder-list-view-container'
import GatewayAdaptersListViewContainer from '../gateway-adapters/containers/gateway-adapters-list-view-container'
import TierStructureListViewContainer from '../tier-structures/containers/tier-structure-list-view-container'
import BatchActionsListViewContainer from '../batch-actions/containers/batch-actions-list-view-container'
import EventsListViewContainer from '../events/containers/events-list-view-container'
import BatchLoggingListViewContainer from '../batch-logging/containers/batch-logging-list-view-container'
import NodeRedListViewContainer from '../node-red/containers/node-red-list-view-container'
import SolutionsListViewContainer from '../solutions/containers/solutions-list-view-container'
import CoreTabHome from './components/core-tab-home'

import AdminGeneral from './components/admin/admin-general'
import AdminImport from './components/admin/admin-import'
import AdminExport from './components/admin/admin-export'

/*
  PUBLIC FUNCTIONS
*/
export const getFile = (fileId, fileName) => {
  MemoryStore.agilite.Files.getFile(fileId)
    .then((response) => {
      const blob = new Blob([response.data])
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
    })
    .catch((error) => {
      // TODO: Need to better manage this exception
      console.log(error)
      return false
    })
}

export const deleteFile = (fileId) => {
  MemoryStore.agilite.Files.deleteFile(fileId)
    .then((response) => {
      return null
    })
    .catch((error) => {
      // TODO: Need to better manage this exception
      console.log(error)
      return null
    })
}

export const adminFetchAccountData = (callback) => {
  return (dispatch) => {
    // TODO: Should there be a dispatch if it's not used?
    MemoryStore.agilite.Utils.account()
      .then((response) => {
        callback(null, response)
      })
      .catch((error) => {
        callback(error, null)
      })
  }
}

export const adminExportData = (data, callback) => {
  const tmpArray = []

  for (const x in data) if (data[x] === true) tmpArray.push(x)

  MemoryStore.agilite.Utils.exportData(tmpArray.join(','))
    .then((response) => {
      const blob = new Blob([response.data])
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = response.headers['file-name']
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      callback(null, response)
    })
    .catch((error) => {
      // TODO: Need to better manage this exception
      callback(error, null)
    })
}

export const postData = (appId, method, data, headers) => {
  return MemoryStore.agilite.executeCRUDRequest(appId, method, data, headers)
}

export const createProfile = (app, callback) => {
  const closable = true
  let entry = null
  let tabType = ''
  let title = ''
  let content = null
  let key = ''
  let payload = {}
  let tempEntry = null
  let tempEntry2 = null

  // Create Entry based on App
  entry = JSON.parse(JSON.stringify(MemoryStore[app].dataTemplate))

  entry._id = v1()
  entry.custom.tempId = entry._id
  MemoryStore.activeEntries[entry._id] = entry

  // Setup Defaults
  tabType = Enums.VALUES_STRINGS.FORM
  key = entry._id
  title = Enums.VALUES_STRINGS.NEW_PROPER_CASE + Enums.VALUES_STRINGS.SPACE + Enums.APP_PROFILE_TITLES[app]

  // MODULECONDITION: Setup Data Object based on App
  switch (app) {
    case Enums.APP_IDS.KEYWORDS:
      content = KeywordsFormContainer
      break
    case Enums.APP_IDS.TIER_STRUCTURES:
      content = TierStructureFormContainer
      break
    case Enums.APP_IDS.NUMBERING:
      content = NumberingFormContainer
      break
    case Enums.APP_IDS.CONNECTORS:
      content = ConnectorsFormContainer
      break
    case Enums.APP_IDS.TEAM_USERS:
      content = TeamUsersFormContainer
      break
    case Enums.APP_IDS.ROLES:
      content = RolesFormContainer
      break
    case Enums.APP_IDS.API_KEYS:
      content = ApiKeysFormContainer
      break
    case Enums.APP_IDS.TEMPLATES:
      content = TemplatesFormContainer
      break
    case Enums.APP_IDS.DATA_MAPPING:
      content = DataMappingFormContainer
      break
    case Enums.APP_IDS.BPM:
      content = BpmFormContainer

      // Create a default First Step & Completed Step for BPM Process
      tempEntry = JSON.parse(JSON.stringify(MemoryStore[app].processStepTemplate))

      tempEntry._id = v1()
      tempEntry.isNewEntry = false
      tempEntry.stepType = 'First Step'
      tempEntry.key = 'first_step'
      tempEntry.name = 'First Step'
      tempEntry.description = 'Auto Generated First Step. This can be modified'
      tempEntry.duration = '0'
      tempEntry.processStage = 'Draft'
      tempEntry.responsibleRole = 'Originator'
      tempEntry.eventStamp[0] = {
        value: 'DateCreated'
      }
      tempEntry.visibleObjects = []
      tempEntry.iln = {
        name: {
          [MemoryStore.defaultLanguage]: 'First Step'
        },
        description: {
          [MemoryStore.defaultLanguage]: 'Auto Generated First Step. This can be modified'
        },
        processStage: {
          [MemoryStore.defaultLanguage]: 'Draft'
        }
      }

      // Create a default Option for the First Step
      tempEntry2 = JSON.parse(JSON.stringify(MemoryStore[app].stepOptionTemplate))

      tempEntry2._id = v1()
      tempEntry2.isNewEntry = false
      tempEntry2.key = 'submit'
      tempEntry2.name = 'Submit'
      tempEntry2.description = 'Auto Generated Option. This can be modified'
      tempEntry2.nextStep = 'completed_step'
      tempEntry2.eventStamp[0] = {
        value: 'DateSubmitted'
      }
      tempEntry2.visibleObjects = []
      tempEntry2.iln = {
        name: {
          [MemoryStore.defaultLanguage]: 'Submit'
        },
        description: {
          [MemoryStore.defaultLanguage]: 'Auto Generated Option. This can be modified'
        }
      }

      // Finalize
      tempEntry.stepOptions.push(tempEntry2)
      entry.data.processSteps.push(tempEntry)

      // Completed Process Step
      tempEntry = JSON.parse(JSON.stringify(MemoryStore[app].processStepTemplate))

      tempEntry._id = v1()
      tempEntry.isNewEntry = false
      tempEntry.stepType = 'Completed Step'
      tempEntry.key = 'completed_step'
      tempEntry.name = 'Completed Step'
      tempEntry.description = 'Auto Generated Completed Step. This can be modified'
      tempEntry.duration = '0'
      tempEntry.processStage = 'Completed'
      tempEntry.responsibleRole = ''
      tempEntry.eventStamp[0] = {
        value: 'DateCompleted'
      }
      tempEntry.visibleObjects = []
      tempEntry.iln = {
        name: {
          [MemoryStore.defaultLanguage]: 'Completed Step'
        },
        description: {
          [MemoryStore.defaultLanguage]: 'Auto Generated Completed Step. This can be modified'
        },
        processStage: {
          [MemoryStore.defaultLanguage]: 'Completed'
        }
      }

      entry.data.processSteps.push(tempEntry)

      break
    case Enums.APP_IDS.BOT_BUILDER:
      content = BotBuilderFormContainer
      break
    case Enums.APP_IDS.GATEWAY_ADAPTERS:
      content = GatewayAdaptersFormContainer
      break
    case Enums.APP_IDS.BATCH_ACTIONS:
      content = BatchActionsFormContainer
      break
    case Enums.APP_IDS.EVENTS:
      content = EventsFormContainer
      break
    case Enums.APP_IDS.BATCH_LOGGING:
      content = BatchLoggingFormContainer
      break
    case Enums.APP_IDS.NODE_RED:
      content = NodeRedFormContainer
      break
    case Enums.APP_IDS.SOLUTIONS:
      content = SolutionsFormContainer
      break
    default:
      // We shouldn't get here
      callback(null)
  }

  payload = {
    content,
    title,
    key,
    app,
    tabType,
    closable
  }

  callback(payload)
}

export const editProfile = (app, recordId, viewData, callback) => {
  const closable = true
  let entry = null
  let tabType = ''
  let title = ''
  let content = null
  let key = ''
  let payload = {}

  // Create Entry based on App
  entry = viewData.find((t) => t._id === recordId)
  MemoryStore.activeEntries[entry._id] = JSON.parse(JSON.stringify(entry))

  // Setup Defaults
  tabType = Enums.VALUES_STRINGS.FORM
  key = entry._id
  title = Enums.APP_PROFILE_TITLES[app] + Enums.VALUES_STRINGS.COLON + Enums.VALUES_STRINGS.SPACE

  // MODULECONDITION: Setup Data Object based on App
  switch (app) {
    case Enums.APP_IDS.KEYWORDS:
      title += entry.data.key
      content = KeywordsFormContainer
      break
    case Enums.APP_IDS.TIER_STRUCTURES:
      title += entry.data.key
      content = TierStructureFormContainer
      break
    case Enums.APP_IDS.NUMBERING:
      title += entry.data.key
      content = NumberingFormContainer
      break
    case Enums.APP_IDS.CONNECTORS:
      title += entry.data.key
      content = ConnectorsFormContainer
      break
    case Enums.APP_IDS.TEAM_USERS:
      title += entry.firstName + Enums.VALUES_STRINGS.SPACE + entry.lastName
      content = TeamUsersFormContainer
      break
    case Enums.APP_IDS.ROLES:
      title += entry.data.name
      content = RolesFormContainer

      if (entry.data.levels.length === 0) entry.data.levels.push('')

      break
    case Enums.APP_IDS.API_KEYS:
      title += entry.firstName
      content = ApiKeysFormContainer
      break
    case Enums.APP_IDS.TEMPLATES:
      title += entry.data.key
      content = TemplatesFormContainer
      break
    case Enums.APP_IDS.DATA_MAPPING:
      title += entry.data.key
      content = DataMappingFormContainer
      break
    case Enums.APP_IDS.BPM:
      title += entry.data.key
      content = BpmFormContainer
      break
    case Enums.APP_IDS.BOT_BUILDER:
      title += entry.data.key
      content = BotBuilderFormContainer
      break
    case Enums.APP_IDS.GATEWAY_ADAPTERS:
      title += entry.data.name
      content = GatewayAdaptersFormContainer
      break
    case Enums.APP_IDS.BATCH_ACTIONS:
      title += entry.data.key
      content = BatchActionsFormContainer
      break
    case Enums.APP_IDS.EVENTS:
      title += entry.data.key
      content = EventsFormContainer
      break
    case Enums.APP_IDS.BATCH_LOGGING:
      title += entry.data.key
      content = BatchLoggingFormContainer
      break
    case Enums.APP_IDS.NODE_RED:
      title += entry.data.key
      content = NodeRedFormContainer
      break
    case Enums.APP_IDS.SOLUTIONS:
      title += entry.data.key
      content = SolutionsFormContainer
      break
    default:
      // We shouldn't get here
      callback()
  }

  payload = { content, title, key, app, tabType, closable }
  callback(payload)
}

export const importData = (data, callback) => {
  const tmpArray = []
  const solutionsArray = []
  const includeData = data.includeData
  const includeCredentials = data.includeCredentials

  for (const x in data.checked) if (data.checked[x] === true) tmpArray.push(x)
  for (const y in data.solutions) solutionsArray.push(data.solutions[y])

  MemoryStore.agilite.Utils.importData(
    data.attachments[0]._id,
    tmpArray.join(','),
    solutionsArray.join(','),
    includeData.toString(),
    includeCredentials.toString()
  )
    .then((response) => {
      callback(null, response.data)
    })
    .catch((error) => {
      console.log(error)
      callback(error)
    })

  return null
}

export const exportAllData = (data, callback) => {
  const tmpArray = []
  const solutionsArray = []
  const includeData = data.includeData
  const includeCredentials = data.includeCredentials

  for (const x in data.checked) if (data.checked[x] === true) tmpArray.push(x)
  if (data.filterBySolutions) for (const y in data.solutions) solutionsArray.push(data.solutions[y])

  MemoryStore.agilite.Utils.exportData(
    tmpArray.join(','),
    solutionsArray.join(','),
    includeData.toString(),
    includeCredentials.toString()
  )
    .then((response) => {
      const blob = new Blob([response.data])
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = response.headers['file-name']
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      callback(null, response)
    })
    .catch((error) => {
      callback(error)
    })
}

export const handleSwitchMenu = (e) => {
  const key = e.key
  const app = e.key
  const tabType = Enums.VALUES_STRINGS.VIEW
  const closable = true
  let content = null
  let canAddTab = true
  let title = Enums.APP_VIEW_TITLES[key]

  // MODULECONDITION
  switch (key) {
    case Enums.APP_IDS.HOME:
      content = <CoreTabHome />
      break
    case Enums.APP_IDS.KEYWORDS:
      content = <KeywordsListViewContainer />
      break
    case Enums.APP_IDS.TIER_STRUCTURES:
      content = <TierStructureListViewContainer />
      break
    case Enums.APP_IDS.NUMBERING:
      content = <NumberingListViewContainer />
      break
    case Enums.APP_IDS.CONNECTORS:
      content = <ConnectorsListViewContainer />
      break
    case Enums.APP_IDS.TEAM_USERS:
      content = <TeamUsersListViewContainer />
      break
    case Enums.APP_IDS.ROLES:
      content = <RolesListViewContainer />
      break
    case Enums.APP_IDS.API_KEYS:
      content = <ApiKeysListViewContainer />
      break
    case Enums.APP_IDS.TEMPLATES:
      content = <TemplatesListViewContainer />
      break
    case Enums.APP_IDS.DATA_MAPPING:
      content = <DataMappingListViewContainer />
      break
    case Enums.APP_IDS.BPM:
      content = <BpmListViewConatiner />
      break
    case Enums.APP_IDS.BOT_BUILDER:
      content = <BotBuilderListViewContainer />
      break
    case Enums.APP_IDS.GATEWAY_ADAPTERS:
      content = <GatewayAdaptersListViewContainer />
      break
    case Enums.APP_IDS.BATCH_ACTIONS:
      content = <BatchActionsListViewContainer />
      break
    case Enums.APP_IDS.EVENTS:
      content = <EventsListViewContainer />
      break
    case Enums.APP_IDS.BATCH_LOGGING:
      content = <BatchLoggingListViewContainer />
      break
    case Enums.APP_IDS.NODE_RED:
      content = <NodeRedListViewContainer />
      break
    case Enums.APP_IDS.SOLUTIONS:
      content = <SolutionsListViewContainer />
      break
    case 'AdminAccount':
      title = 'Account'
      content = <AdminAccountContainer />
      break
    case 'Dashboard':
      title = 'Dashboard'
      content = <DashboardContainer />
      break
    case Enums.APP_IDS.ERROR_LOGS:
      title = 'Error Logs'
      content = <ErrorLogsContainer />
      break
    case 'About':
      content = <AdminGeneral />
      title = 'About'
      break
    case 'Import':
      content = <AdminImport />
      title = 'Import'
      break
    case 'Export':
      content = <AdminExport />
      title = 'Export'
      break
    case Enums.APP_IDS.NODE_RED_DASHBOARD:
      // Launch Node-RED
      canAddTab = false
      window.open(Globals.config.nodeRedUrl)
      break
    default:
      // Do Nothing
      return null
  }

  if (canAddTab) {
    store.dispatch({
      type: actions.UPDATE_TABS,
      payload: {
        content,
        title,
        key,
        app,
        tabType,
        closable
      }
    })
  }
}
