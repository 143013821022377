import React from 'react'
import MemoryStore from '../../utils/memory-store'
import { Row, Col, Select, Form, Divider } from 'antd'

class APIKeysFormPrivileges extends React.Component {
  render() {
    return (
      <Col xs={24} lg={12}>
        <h3>Privileges</h3>
        <Divider />
        <Row gutter={20}>
          <Col xs={24} lg={12}>
            <h4>Modules</h4>
            <Form.Item>
              Keywords
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.keywords}
                onChange={(value) => {
                  this.props.onFieldChange('keywords', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Reader'>Reader</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              Tier Structures
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.tierstructures}
                onChange={(value) => {
                  this.props.onFieldChange('tierstructures', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Reader'>Reader</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              Numbering
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.numbering}
                onChange={(value) => {
                  this.props.onFieldChange('numbering', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Reader'>Reader</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              Connectors
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.connectors}
                onChange={(value) => {
                  this.props.onFieldChange('connectors', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Reader'>Reader</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              Data Mappings
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.datamappings}
                onChange={(value) => {
                  this.props.onFieldChange('datamappings', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Reader'>Reader</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              Templates
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.templates}
                onChange={(value) => {
                  this.props.onFieldChange('templates', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Reader'>Reader</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              Business Processes
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.bpm}
                onChange={(value) => {
                  this.props.onFieldChange('bpm', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Reader'>Reader</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              Roles
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.roles}
                onChange={(value) => {
                  this.props.onFieldChange('roles', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Reader'>Reader</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              Gateway Adapters
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.gatewayadapters}
                onChange={(value) => {
                  this.props.onFieldChange('gatewayadapters', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Reader'>Reader</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              Batch Actions
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.batchactions}
                onChange={(value) => {
                  this.props.onFieldChange('batchactions', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Reader'>Reader</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              Events
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.events}
                onChange={(value) => {
                  this.props.onFieldChange('events', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Reader'>Reader</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              Batch Logging
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.batchlogging}
                onChange={(value) => {
                  this.props.onFieldChange('batchlogging', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Reader'>Reader</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              Node-RED
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.nodered}
                onChange={(value) => {
                  this.props.onFieldChange('nodered', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Reader'>Reader</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <h4>Administration</h4>
            <Form.Item>
              Dashboards
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.dashboards}
                onChange={(value) => {
                  this.props.onFieldChange('dashboards', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              Teams
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.teams}
                onChange={(value) => {
                  this.props.onFieldChange('teams', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Reader'>Reader</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              API Keys
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.apikeys}
                onChange={(value) => {
                  this.props.onFieldChange('apikeys', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Reader'>Reader</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              Import / Export
              <Select
                disabled={MemoryStore.userProfile.teamPrivileges.apikeys === 'Reader'}
                value={this.props.entry.teamRights.privileges.importexport}
                onChange={(value) => {
                  this.props.onFieldChange('importexport', value)
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                <Select.Option value='Editor'>Editor</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default APIKeysFormPrivileges
