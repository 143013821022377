import MemoryStore from '../utils/memory-store'
import { formatRecordData } from '../utils/utilities'
import BpmUtilsFormContainer from '../bpm/containers/bpm-form-container'
import Enums from '../utils/enums'

export const filterViewData = (viewData, searchFilter) => {
  searchFilter = searchFilter.toLowerCase()

  if (!searchFilter) {
    return viewData
  } else {
    return viewData.filter(
      (t) =>
        t.data.name.toLowerCase().indexOf(searchFilter) > -1 ||
        t.data.key.toLowerCase().indexOf(searchFilter) > -1 ||
        t.data.groupName.toLowerCase().indexOf(searchFilter) > -1 ||
        t.data.description.toLowerCase().indexOf(searchFilter) > -1
    )
  }
}

export const filterProcessStepViewData = (data, searchFilter, callback) => {
  searchFilter = searchFilter.toLowerCase()

  if (!searchFilter) {
    return callback(data)
  } else {
    return callback(
      data.filter(
        (t) =>
          t.name.toLowerCase().indexOf(searchFilter) > -1 ||
          t.key.toLowerCase().indexOf(searchFilter) > -1 ||
          t.description.toLowerCase().indexOf(searchFilter) > -1
      )
    )
  }
}

export const filterStepOptionViewData = (data, searchFilter, callback) => {
  searchFilter = searchFilter.toLowerCase()

  if (!searchFilter) {
    return callback(data)
  } else {
    return callback(
      data.filter(
        (t) => t.name.toLowerCase().indexOf(searchFilter) > -1 || t.description.toLowerCase().indexOf(searchFilter) > -1
      )
    )
  }
}

export const formatRecord = (entry, data) => {
  let tempIndex = 0

  entry = formatRecordData(entry)
  entry.data.isActiveDescription = entry.data.isActive ? 'Yes' : 'No' // Needed for View Column

  if (entry.custom.isNewDoc) {
    // Add new Entry to State
    entry.custom.isNewDoc = false
    entry.custom.action = 'create'
    data.push(entry)
  } else {
    if (entry.custom.isSavedDoc) {
      entry.custom.action = 'update'
    } else {
      entry.custom.action = 'create'
    }

    // Update Entry in State
    tempIndex = data.findIndex((t) => t._id === entry._id)
    data[tempIndex] = entry
  }

  return data
}

export const clearHistoryData = (key) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let result = null
      let errMsg = null

      try {
        result = await MemoryStore.agilite.BPM.clearHistoryData(key)
        resolve(result)
      } catch (error) {
        if (error.response) {
          errMsg = error.response.data.errorMessage
        } else if (error.message) {
          errMsg = error.message
        } else {
          errMsg = Enums.MESSAGES.UNKNOWN_ERROR
        }

        reject(errMsg)
      }
    })()
  })
}

export const reAssignResponsibleUser = (processKey, currentResponsibleUser, newResponsibleUser, roles) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let result = null
      let errMsg = null

      try {
        result = await MemoryStore.agilite.BPM.reAssignResponsibleUser(
          processKey,
          currentResponsibleUser,
          newResponsibleUser,
          roles
        )
        resolve(result)
      } catch (error) {
        if (error.response) {
          errMsg = error.response.data.errorMessage
        } else if (error.message) {
          errMsg = error.message
        } else {
          errMsg = Enums.MESSAGES.UNKNOWN_ERROR
        }

        reject(errMsg)
      }
    })()
  })
}

export const getResponsibleRoles = (processKey, currentResponsibleUser) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let result = null
      let errMsg = null

      try {
        result = await MemoryStore.agilite.BPM.getResponsibleRoles(processKey, currentResponsibleUser)
        resolve(result)
      } catch (error) {
        if (error.response) {
          errMsg = error.response.data.errorMessage
        } else if (error.message) {
          errMsg = error.message
        } else {
          errMsg = Enums.MESSAGES.UNKNOWN_ERROR
        }

        reject(errMsg)
      }
    })()
  })
}

export const getRoleProfiles = () => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let result = null
      let errMsg = null

      try {
        result = await MemoryStore.agilite.Roles.getData()
        resolve(result)
      } catch (error) {
        if (error.response) {
          errMsg = error.response.data.errorMessage
        } else if (error.message) {
          errMsg = error.message
        } else {
          errMsg = Enums.MESSAGES.UNKNOWN_ERROR
        }

        reject(errMsg)
      }
    })()
  })
}

export const getNumberingProfiles = () => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let result = null
      let errMsg = null

      try {
        result = await MemoryStore.agilite.Numbering.getData()
        resolve(result)
      } catch (error) {
        if (error.response) {
          errMsg = error.response.data.errorMessage
        } else if (error.message) {
          errMsg = error.message
        } else {
          errMsg = Enums.MESSAGES.UNKNOWN_ERROR
        }

        reject(errMsg)
      }
    })()
  })
}

export const saveRoleProfile = (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let result = null
      let errMsg = null

      try {
        result = await MemoryStore.agilite.Roles.postData(data)
        resolve(result)
      } catch (error) {
        if (error.response) {
          errMsg = error.response.data.errorMessage
        } else if (error.message) {
          errMsg = error.message
        } else {
          errMsg = Enums.MESSAGES.UNKNOWN_ERROR
        }

        reject(errMsg)
      }
    })()
  })
}

export const cleanupData = (data) => {
  let entry = null
  const tempArray = data.concat()

  // First Strip empty values from array
  for (let x = 0, y = tempArray.length; x < y; x++) {
    entry = tempArray[x]

    // Remove key prop
    delete tempArray[x].key

    if (!entry.label.trim() && !entry.value.trim()) {
      tempArray.splice(x, 1)
      x--
      y = tempArray.length
    }
  }

  // Next, trim labels and values
  for (const x in tempArray) {
    entry = tempArray[x]
    entry.label = entry.label.trim()
    entry.value = entry.value.trim()
  }

  return tempArray
}

export const createCopy = (currEntryData, callback) => {
  const newEntry = JSON.parse(JSON.stringify(MemoryStore.bpm.dataTemplate))
  newEntry._id = new Date().getTime().toString()
  newEntry.custom.tempId = newEntry._id

  const entryData = JSON.parse(JSON.stringify(currEntryData))
  const tabType = Enums.VALUES_STRINGS.FORM
  const content = BpmUtilsFormContainer
  const closable = true
  const key = newEntry._id
  const app = Enums.APP_IDS.BPM

  let title = Enums.APP_PROFILE_TITLES.bpm + Enums.VALUES_STRINGS.COLON + Enums.VALUES_STRINGS.SPACE
  let payload = {}

  // create unique key
  entryData.key = currEntryData.key + '_copy'
  entryData.name = currEntryData.name + ' (Copy)'

  newEntry.data = entryData

  title += entryData.key

  payload = {
    content,
    title,
    key,
    app,
    tabType,
    closable
  }

  MemoryStore.activeEntries[newEntry._id] = newEntry

  callback(payload)
}
